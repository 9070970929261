import 'reflect-metadata';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initializeUserAnalytics } from './common/metrics/InitializeUserAnalytics';
import App from './containers/App';
import './i18n';
import './index.css';
import '@smartsheet/lodestar-core/dist/fonts/fonts.css';
import { unregister } from './RegisterServiceWorker';
import configureStore from './store/ConfigureStore';

const history = createBrowserHistory();
export const store = configureStore(history);
initializeUserAnalytics();

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement
);

unregister();
