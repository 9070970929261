import styled from '@emotion/styled';
import * as React from 'react';
import { Link, colors, sizes, useTooltip } from '@smartsheet/lodestar-core';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { useSelector } from 'react-redux';
import { isUserEligibleOrInTrial, isUserLicensedSelector, userEligibilitySelector } from '../../containers/Auth/Selectors';
import { EligibilityStatus } from '../../containers/Auth/interfaces/EligibilityStatusEnum';
import { useEffect, useState } from 'react';
import { StyledTooltip } from '../DvStyledTooltip/StyledTooltip';
import usersClient from '../../http-clients/Users.client';

const TrialContainer = styled.div((props: any) => ({
    boxSizing: 'border-box',
    backgroundColor: props.active ? colors.visGreenLight30 : colors.buttonDefault,
    color: props.active ? colors.buttonUpgrade : colors.neutralDark20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: sizes.xLarge,
    borderRadius: sizes.xLarge,
    padding: `${sizes.xSmall}px ${sizes.small}px`,
    fontSize: '13px',
    fontWeight: 500,
}));

const TrialOrb = styled.div((props: any) => ({
    boxSizing: 'border-box',
    display: 'inline-block',
    height: sizes.small,
    width: sizes.small,
    backgroundColor: props.active ? colors.buttonUpgrade : 'transparent',
    borderRadius: sizes.small,
    borderWidth: props.active ? '0px' : '1px',
    borderStyle: props.active ? 'none' : 'solid',
    borderColor: props.active ? 'transparent' : colors.neutralDark10,
    marginRight: sizes.xSmall,
}));

const StyledList = styled.ul(`
    box-sizing: border-box;
    max-width: 400px;
    white-space: normal;
    margin: 0;
    padding: ${sizes.xxSmall}px;
    padding-left: ${sizes.medium}px;
    padding-right: 0px;
    line-height: 18px;
`);

const tooltipHoverTimeoutMs = 400;

export const TrialModeIndicator = () => {
    const EVAL_MODE_HELP_URL = 'https://help.smartsheet.com/articles/2483039-Advance-Capabilities-Evaluations';
    const languageElements = useLanguageElements();
    const authStatus = useSelector(userEligibilitySelector);
    const isUserLicensed = useSelector(isUserLicensedSelector);
    const isEligible = useSelector(isUserEligibleOrInTrial);
    const isNotEligible = !isEligible;

    const [adminEmail, setAdminEmail] = useState<string>('');

    // Fetch sys-admin email for eligible user
    useEffect(() => {
        if (isUserLicensed || isNotEligible) {
            return;
        }
        usersClient
            .getAdminEmail()
            .then((email) => setAdminEmail(email))
            .catch(() => setAdminEmail(''));
    }, [isNotEligible, isUserLicensed]);

    const { tooltipProps, targetProps } = useTooltip<HTMLDivElement>();

    const [tooltipTimer, setTooltipTimer] = useState<NodeJS.Timeout | null>(null);
    const setStateAndClearTooltipTimer =
        <T,>(setState: React.Dispatch<React.SetStateAction<T>>) =>
        (newState: T) => {
            if (tooltipTimer) {
                clearTimeout(tooltipTimer);
            }
            setTooltipTimer(null);
            setState(newState);
        };

    const [tooltipIsOpenHover, _setTooltipIsOpenHover] = useState<boolean>(false);
    const setTooltipIsOpenHover = setStateAndClearTooltipTimer(_setTooltipIsOpenHover);

    const onHover = () => {
        setTooltipIsOpenHover(true);
    };

    const onHoverOff = () => {
        const timer = setTimeout(() => {
            setTooltipIsOpenHover(false);
        }, tooltipHoverTimeoutMs);
        setTooltipTimer(timer);
    };

    if (isEligible) {
        const activeTrial = authStatus === EligibilityStatus.ALREADY_IN_TRIAL;
        return (
            <React.Fragment>
                <TrialContainer
                    {...targetProps}
                    active={activeTrial}
                    onMouseEnter={() => {
                        onHover();
                    }}
                    onMouseLeave={() => {
                        onHoverOff();
                    }}
                >
                    <TrialOrb active={activeTrial} />
                    {languageElements.TRIAL_CHIP_LABEL}
                </TrialContainer>
                <StyledTooltip
                    {...tooltipProps}
                    placement={'bottom-end'}
                    hasArrow={false}
                    portalProps={{ zIndex: 11 }}
                    offset={{ distance: 8 }}
                    isOpen={tooltipIsOpenHover}
                    onMouseEnter={() => {
                        onHover();
                    }}
                    onMouseLeave={() => {
                        onHoverOff();
                    }}
                >
                    <StyledList>
                        {activeTrial ? (
                            <li> {languageElements.TRIAL_TOOLTIP_ONE_IN_TRIAL(adminEmail)} </li>
                        ) : (
                            <li> {languageElements.TRIAL_TOOLTIP_ONE_PRE_TRIAL} </li>
                        )}
                        <li>
                            {languageElements.TRIAL_TOOLTIP_TWO} <Link href={EVAL_MODE_HELP_URL}>{languageElements.TRIAL_TOOLTIP_LINK_TEXT}</Link>
                        </li>
                    </StyledList>
                </StyledTooltip>
            </React.Fragment>
        );
    } else {
        return <></>;
    }
};
