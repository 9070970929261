import * as React from 'react';

import dynamicViewIcon from '../../assets/images/dynamicViewIcon/dynamicView-Launcher-Icon@1x.svg';
import './StatusViewIcon.css';

const statusViewIcon: React.SFC = () => (
    <div className="statusViewIcon">
        <img
            src={dynamicViewIcon}
            alt="DynamicView"
        />
    </div>
);

export default statusViewIcon;
