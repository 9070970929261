import * as React from 'react';
import dynamicViewLogo from '../../assets/images/dynamicViewLogo/dynamic-view-login-logo.svg';
import './StatusViewLogo.css';

const statusViewLogo: React.SFC = () => (
    <div className="statusViewLogo">
        <img
            src={dynamicViewLogo}
            alt="DynamicView"
        />
    </div>
);

export default statusViewLogo;
