import styled from '@emotion/styled';
import { Avatar } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { useState } from 'react';
import { dateTimeFormat } from '../../../../common/DateTimeFormat';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import CommentMenu from '../../../../components/Buttons/CommentMenu/CommentMenu';
import CommentInput from './CommentInput';
import { UnsavedComment } from './Conversation';
import { getInitials } from './ConversationUtils';
import { CommentType, User } from './types';
import { CommentHeader, FlexGrow } from './Comment';

export interface ReplyProps {
    viewId: string;
    rowId: string;
    reportSheetId?: number;
    reply: CommentType;
    unsavedValue: UnsavedComment;
    currentUser: User;
    onClickDeleteReply: (replyId: string) => void;
    onSave: (unsavedItem: UnsavedComment) => Promise<void>;
    onChangeUnsavedReplyOrComment: (unsavedItem: UnsavedComment) => void;
}

const Reply: React.FC<ReplyProps> = ({
    viewId,
    rowId,
    reportSheetId,
    reply,
    unsavedValue,
    currentUser,
    onClickDeleteReply,
    onSave,
    onChangeUnsavedReplyOrComment,
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const createdAt = dateTimeFormat(currentUser.locale, currentUser.timezone).format(new Date(reply.createdAt));
    const currentUserIsCommentOwner = reply.createdByUser?.emailAddress?.toLowerCase() === currentUser.emailAddress.toLowerCase();
    const userName = `${reply.createdByUser?.firstName || ''} ${reply.createdByUser?.lastName || ''}`.trim();

    const handleSave = async (text: string) => {
        setIsEditing(false);
        const unsavedItem: UnsavedComment = { commentId: reply.id, parentCommentId: reply.parentCommentId, text };
        await onSave(unsavedItem);
    };
    const handleClickEditComment = () => setIsEditing(true);
    const handleClickDeleteComment = () => onClickDeleteReply(reply.id);

    return (
        <div data-client-type={AutomationIds.COMMENT_COMMENTS_SUB_ITEM} className={'comment-wrap sub-comment'}>
            <div className="comment-content">
                <CommentHeader className="comment-header">
                    <AvatarStyled
                        size="xxSmall"
                        clientId="CONTACT_ICON"
                        initials={getInitials(reply.createdByUser.firstName, reply.createdByUser.lastName)}
                        email={reply.createdByUser.emailAddress}
                        src={reply.createdByUser.profileImage?.url ?? ''}
                        aria-label="Profile Image"
                    />
                    <FlexGrow>
                        <span data-client-id={AutomationIds.COMMENT_COMMENTS_CREATOR} className="comment-creator">
                            <b>{userName}</b>
                        </span>
                        {!isEditing && (
                            <span data-client-id={AutomationIds.COMMENT_COMMENTS_DATE} className="comment-date">
                                {' '}
                                | {createdAt}
                            </span>
                        )}
                    </FlexGrow>
                    <CommentMenu
                        data-testid="comment-menu"
                        for={reply.text}
                        onClickEditComment={currentUserIsCommentOwner ? handleClickEditComment : undefined}
                        onClickDeleteComment={currentUserIsCommentOwner ? handleClickDeleteComment : undefined}
                    />
                </CommentHeader>
                {!isEditing && (
                    <div className="comment-text" data-client-id={AutomationIds.COMMENT_COMMENTS_TEXT}>
                        {reply ? reply.text : ''}
                    </div>
                )}
                {isEditing && (
                    <CommentInput
                        className={'add-comment-wrap'}
                        currentUser={currentUser}
                        viewId={viewId}
                        rowId={rowId}
                        commentId={reply.id}
                        parentCommentId={reply.parentCommentId}
                        reportSheetId={reportSheetId}
                        value={unsavedValue.text || reply.text}
                        isEditing={true}
                        onChangeUnsavedReplyOrComment={(unsavedComment: UnsavedComment) => {
                            onChangeUnsavedReplyOrComment(unsavedComment);
                        }}
                        onSave={() => handleSave(unsavedValue.text)}
                        closeCommentInput={() => setIsEditing(false)}
                        onCancel={() => {
                            setIsEditing(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Reply;

const AvatarStyled = styled(Avatar)`
    margin-left: -25px;
    margin-right: 5px;
    margin-bottom: 5px;
`;
