import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { createUrlPath } from '../common/utils/urlUtils';
import { userService } from '../services/User.service';
export class AxiosFactory {
    public static create(responseInterceptor?: {
        onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
        onError: (error: AxiosError) => void;
    }): AxiosInstance {
        const newAxiosInstance = axios.create({
            baseURL: createUrlPath(`/api`),
            timeout: 180000,
        });

        newAxiosInstance.interceptors.request.use(
            (config) => {
                const token = userService.getToken();
                if (token) {
                    config.headers.authorization = `Bearer ${token}`;
                }
                config.headers['Cache-Control'] = 'no-cache';

                // Retrieve the x-smar-xsrf token from session storage
                const xsrfToken = window.sessionStorage.getItem('x-smar-xsrf');
                if (xsrfToken) {
                    config.headers['x-smar-xsrf'] = xsrfToken;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        if (responseInterceptor) {
            newAxiosInstance.interceptors.response.use(responseInterceptor.onSuccess, responseInterceptor.onError);
        }

        return newAxiosInstance;
    }
}
