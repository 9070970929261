import styled from '@emotion/styled';
import { Avatar } from '@smartsheet/lodestar-core';
import * as classNames from 'classnames';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';
import { isNullOrEmptyString } from '../../../../common/utils';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import { AtMentions as AtMentionsClass, default as AtMentionsWithHOC } from '../AtMentions/AtMentions';
import { UnsavedComment } from './Conversation';
import { getInitials } from './ConversationUtils';
import { User } from './types';

export interface CommentInputProps {
    className?: string;
    viewId: string;
    rowId: string;
    reportSheetId?: number;
    value: string;
    commentId: string;
    parentCommentId: string;
    isEditing: boolean;
    currentUser: User;
    onSave: (unsavedItem: UnsavedComment) => Promise<void>;
    onChangeUnsavedReplyOrComment: (unsavedItem: UnsavedComment) => void;
    closeCommentInput?: () => void;
    onCancel?: () => void;
}

const ControlId = {
    SAVE: 'cmt-1',
    CANCEL: 'cmt-2',
};

const CommentInput: React.FC<CommentInputProps> = (props) => {
    const {
        className,
        currentUser,
        viewId,
        rowId,
        reportSheetId,
        value,
        commentId,
        parentCommentId,
        isEditing,
        onSave,
        onChangeUnsavedReplyOrComment,
        closeCommentInput,
        onCancel,
    } = props;

    const [showHelpText, setShowHelpText] = useState(false);
    const atMentionsRef = useRef<AtMentionsClass>(null);
    const [currentComment, setCurrentComment] = useState(value || '');

    const languageElements = useLanguageElements();

    useEffect(() => {
        setFocusAfterCommentSubmission();
    }, [onSave]);

    useEffect(() => {
        setCurrentComment(value);
    }, [value]);

    const setFocusAfterCommentSubmission = () => {
        const commentsPanel = document.querySelector('.comments-panel');
        if (commentsPanel) {
            // Copied from app-core:
            // - dev2/web/javascript/legacyApp/src/app2/ReactIntegrationManager.ts:117
            // - dev2/web/javascript/legacyApp/src/core/Common.ts: 757
            const mentionsFlyoutNode = document.createElement('div');
            mentionsFlyoutNode.className = 'mentionsFloatingPortal';
            commentsPanel.appendChild(mentionsFlyoutNode);
        }

        if (atMentionsRef.current) {
            atMentionsRef.current.focus();
        }
    };

    const handleCancel = () => {
        onChangeUnsavedReplyOrComment({ commentId, parentCommentId, text: '' });
        onCancel?.();
    };

    const handleClick = () => {
        handleOnSave(currentComment, isEditing, commentId, parentCommentId);
    };

    const onChange = useCallback(
        (text: string) => {
            onChangeUnsavedReplyOrComment({ commentId, parentCommentId, text });
            setCurrentComment(text);
        },
        [onChangeUnsavedReplyOrComment, commentId, parentCommentId]
    );

    const handleOnSave = useCallback(
        (_: string, _isEditing, _commentId: string, _parentCommentId: string) => {
            if (currentComment === '') {
                onCancel?.();
            } else {
                const unsavedItem = {
                    text: currentComment,
                    commentId: _commentId ?? '0',
                    parentCommentId: _parentCommentId ?? '0',
                };
                onSave(unsavedItem);
            }
            closeCommentInput?.();
            onChange('');
        },
        [currentComment, closeCommentInput, onChange, onCancel, onSave]
    );

    const handleBlur = () => {
        setShowHelpText(false);
        if (isNullOrEmptyString(currentComment.trim())) {
            if (closeCommentInput) {
                closeCommentInput();
            }
            onCancel?.();
        }
    };

    const handleFocus = () => setShowHelpText(true);

    const commentHelpTextStyle: React.CSSProperties = showHelpText ? {} : { visibility: 'hidden' };

    return (
        <div data-client-type={AutomationTypes.COMMENT_REPLY_ITEM_TYPE} className={className}>
            {currentUser && (
                <AvatarAndCommentWrap>
                    {!isEditing ? (
                        <Avatar
                            size="small"
                            clientId={AutomationTypes.CONTACT_ICON}
                            initials={getInitials(currentUser.firstName, currentUser.lastName)}
                            email={currentUser.emailAddress}
                            src={currentUser.profileImage?.url ?? ''}
                            aria-label="Profile Image"
                        />
                    ) : null}
                    <div className={'comment-textarea-wrap'}>
                        <AtMentionsWithHOC
                            ref={atMentionsRef}
                            viewId={viewId}
                            rowId={parseInt(rowId, 10)}
                            reportSheetId={reportSheetId}
                            text={value}
                            onChange={onChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onSubmit={handleClick}
                        />

                        <div className={'button-list'}>
                            <div>
                                <div className={'comment-help-text'} style={commentHelpTextStyle}>
                                    {languageElements.COMMENT_HELP_TEXT}
                                </div>
                            </div>

                            {!isEditing && (
                                <button
                                    data-client-id={AutomationIds.COMMENT_COMMENTS_SEND}
                                    className={classNames('item-icon', 'icon-send', {
                                        active: currentComment.trim() !== '',
                                        hidden: isEditing,
                                    })}
                                    onClick={handleClick}
                                    value={languageElements.COMMENT_SEND}
                                    aria-label={languageElements.COMMENT_SEND}
                                />
                            )}
                        </div>
                    </div>
                </AvatarAndCommentWrap>
            )}
            {isEditing && (
                <div className={'save-and-cancel-buttons'}>
                    <button onClick={() => handleClick()} className="btn state-button btn-primary" data-client-id={ControlId.SAVE}>
                        {languageElements.BUTTON_TEXT_SAVE}
                    </button>
                    <button onClick={() => handleCancel()} className="btn details-data-button btn-secondary" data-client-id={ControlId.CANCEL}>
                        {languageElements.BUTTON_TEXT_CANCEL}
                    </button>
                    <div style={{ clear: 'both' }} />
                </div>
            )}
        </div>
    );
};

export default CommentInput;

const AvatarAndCommentWrap = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
`;
