import * as React from 'react';
import hamburgerImg from '../../assets/images/toolbar/hamburger-menu.svg';
import * as AppActions from '../../containers/App/Actions';
import './HamburgerMenu.css';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

interface HamburgerMenuProps {
    leftSidePanelOpened: boolean;
    toggleLeftSidePanel: () => AppActions.Actions;
}

type Props = HamburgerMenuProps;

export const ControlId = {
    MENU: 'tbm-1',
};

export const HamburgerMenu = (props: Props) => {
    const languageElements = useLanguageElements();
    const onClickHandler = (): void => {
        props.toggleLeftSidePanel();
    };
    const description = props.leftSidePanelOpened ? languageElements.LEFT_PANEL_CLOSE : languageElements.LEFT_PANEL_OPEN;
    return (
        <div className="hamburger-menu" data-client-id={ControlId.MENU} onClick={onClickHandler}>
            <img src={hamburgerImg} alt={description} />
            <div className="tooltip">
                <div>{description}</div>
            </div>
        </div>
    );
};

export default HamburgerMenu;
