import { User } from '../../../../common/interfaces';
import { User as ConversationUser } from '../Comment/types';

// formated user for conversation service, when the userService decupling will be complete,
// this will be removed
export const formatCurrentUser = (user: User): ConversationUser => {
    return {
        id: user.id ?? 'default-id',
        uniqueUserId: user.smartsheetAccountId ?? 'default-id',
        emailAddress: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        locale: user.locale,
        timezone: user.timeZone,
        profileImage: user.profileImgUrl
            ? {
                  url: user.profileImgUrl,
                  size: 'small',
                  profileImageSource: 'global',
                  scope: 'global',
                  metadata: null,
              }
            : undefined,
    };
};

export const getInitials = (firstName: string = '', lastName: string = '') => {
    const firstNameTrimmed = firstName?.trim();
    const lastNameTrimmed = lastName?.trim();
    return `${firstNameTrimmed[0]?.toUpperCase() ?? ''}${lastNameTrimmed[0]?.toUpperCase() ?? ''}`;
};
