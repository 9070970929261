import { AsyncStatus } from '../../../common/enums';
import { HomeView } from '../../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import Spinner, { Color, Size } from '../../../components/Spinner';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { favoriteViews, homeViewStatusSelector } from '../../Home';
import EmptyStateLeftRail from '../EmptyStateLeftRail/EmptyStateLeftRail';
import PanelFrame from '../PanelFrame/PanelFrame';
import FavoritesList from './FavoritesList';

export interface OwnProps {
    activeViewId?: string;
    userIsLoaded: boolean;
}

interface StateProps {
    favoriteStatus: AsyncStatus;
    favoriteViews: HomeView[];
}

export type FavoritesPanelProps = OwnProps & StateProps;

export const FavoritesPanel: React.SFC<FavoritesPanelProps> = (props) => {
    const languageElements = useLanguageElements();

    if (!props.userIsLoaded) {
        return null;
    }

    const getPanelContent = (): React.ReactNode => {
        switch (props.favoriteStatus) {
            case AsyncStatus.ERROR:
                return <EmptyStateLeftRail message={languageElements.FAVORITES_ERROR_MESSAGE} classNames="left-panel-error" />;

            case AsyncStatus.DONE:
                return getFavoriteViews();

            case AsyncStatus.IN_PROGRESS:
                if (props.favoriteViews && props.favoriteViews.length > 0) {
                    return getFavoriteViews();
                }
                return <Spinner color={Color.BLUE} size={Size.SMALL} />;
        }

        return null;
    };

    const getFavoriteViews = (): React.ReactNode => {
        return <FavoritesList activeViewId={props.activeViewId} favoriteViews={props.favoriteViews} />;
    };
    const panelContent = getPanelContent();

    return (
        <PanelFrame titleLabel={languageElements.FAVORITE_TITLE} dataClientId={AutomationIds.FAVORITE_PANEL_TITLE}>
            {panelContent}
        </PanelFrame>
    );
};

const mapState = createStructuredSelector({
    favoriteStatus: homeViewStatusSelector,
    favoriteViews,
});

export default connect<StateProps>(mapState)(FavoritesPanel);
