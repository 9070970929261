import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { userService } from '../../services/User.service';
import { Actions } from './Actions';

interface DispatchProps {
    logOut: () => Actions;
}

export type LogoutProps = DispatchProps;

/**
 * The Log out component, logs the user out on the backend, remove credentails from local storage and redirect the user to the '/login' screen
 */
export const Logout = (props: LogoutProps) => {
    UserAnalyticsGlobalContext.removeAll();
    userService.handleLogOut();
    props.logOut();

    return <Redirect to="/login" />;
};

const mapDispatch = {
    logOut: Actions.logOut,
};

export default connect<null, DispatchProps>(null, mapDispatch)(Logout);
