/* eslint @typescript-eslint/unbound-method:"off",@typescript-eslint/no-misused-promises:"off" */
import { AccessLevel, AccessType } from '../../../common/enums';
import {
    Contact,
    Group,
    IOwnershipTransfer,
    IPaginatedResult,
    ShareError,
    SmartsheetUser,
    ViewConfig,
    ViewShareBasic,
    ViewWithOwnerAndUserDetails,
} from '../../../common/interfaces';
import { isDomainBlocklisted, isEmailValid } from '../../../common/utils';
import { fromJS, List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import validator from 'validator';
import { AdminPanelPermissionsModalType } from '../../../common/enums/AdminPanelPermissionsModalType';
import { dynamicSort } from '../../../common/utils/DynamicSort';
import { mapUsersToContacts, mapUserToContact } from '../../../common/utils/MapUserToContact';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import * as AppActions from '../../App/Actions';
import { Actions } from '../Actions';
import { AdminPanelComponentProps } from '../interfaces/AdminPanelComponentProps.interface';
import { AdminPanelOnChange } from '../interfaces/AdminPanelOnChange.interface';
import {
    makeSelectShareErrors,
    makeSelectSmartsheetAdmins,
    makeSelectSmartsheetGroups,
    makeSelectSmartsheetUsers,
    makeSelectSmartsheetUsersByEmails,
} from '../Selectors';
import AdminPanelPermissions from './AdminPanelPermissions';
import './AdminPanelPermissions.css';
import { safeSharingFeatureSelector } from '../../App/Selectors';
import configSettingClient from '../../../http-clients/ConfigSettingClient';

interface Props extends AdminPanelComponentProps {
    viewShares: ViewShareBasic[];
    allUsers: IPaginatedResult<SmartsheetUser>;
    selectedUsers: IPaginatedResult<SmartsheetUser>;
    allAdmins: SmartsheetUser[];
    allGroups: Group[];
    shareErrors?: ShareError[];
    currentUser: string;
    userInOwnerOrg: boolean | undefined;
    config: ViewConfig;
    view: ViewWithOwnerAndUserDetails;
    pendingTransfer: IOwnershipTransfer | undefined;
    safeSharingFeature: boolean;
}

interface DispatchProps {
    storeShareErrors: () => Actions;
    setAppStageRedirect: (redirectUrl: string) => AppActions.Actions;
}

interface State {
    owner: MultiSelectItem;
    newOwner: Contact | undefined;
    availableAdmins: SmartsheetUser[];
    admins: Contact[];
    shareUsers: Contact[];
    groups: MultiSelectItem[];
    domains: MultiSelectItem[];
    readOnly: boolean;
    showModalType: AdminPanelPermissionsModalType;
    safeSharingEnabled: boolean;
}

const ControlId = {
    OWNER: 'appo',
    ADMINS: 'appa',
    USERS: 'appu',
    DOMAINS: 'appd',
    GROUPS: 'appg',
};

export class AdminPanelPermissionsContainer extends React.Component<Props & DispatchProps & LanguageElementsProp> {
    public state: State;
    private pendingAdmins?: Contact[];

    public constructor(props: Props & DispatchProps & LanguageElementsProp) {
        super(props);
        this.state = this.getInitialState();
    }

    public render(): React.ReactNode {
        const isOwner = this.isCurrentUserViewOwner();
        return (
            <AdminPanelPermissions
                owner={this.state.owner}
                isOwner={isOwner}
                selectedNewOwnerItem={this.props.pendingTransfer ? undefined : this.state.newOwner}
                onNewOwnerChange={(value) => this.handleNewOwnerChange(value)}
                availableAdminsItems={mapUsersToContacts(this.state.availableAdmins)}
                selectedAdminsItems={this.state.admins}
                onAdminsChange={(value) => this.handleOnAdminsChange(value)}
                selectedUsersItems={this.state.shareUsers}
                sharingErrors={this.props.shareErrors}
                allUsersItems={mapUsersToContacts(this.props.allUsers.data)}
                onUsersChange={(value) => this.handleOnUsersChange(value)}
                onUserValidateNewOption={this.handleUserValidateNewOption}
                selectedGroupsItems={this.state.groups}
                allGroupsItems={this.sortAndTransformGroupsToMultiSelectItems(this.props.allGroups, ControlId.GROUPS)}
                onGroupsChange={(value) => this.handleOnGroupsChange(value)}
                selectedDomainsItems={this.state.domains}
                allDomainsItems={[]}
                onDomainsChange={(value) => this.handleOnDomainsChange(value)}
                onWarnAdminCancel={() => this.handleWarnAdminCancel()}
                onWarnAdminConfirm={() => this.handleWarnAdminConfirm()}
                showModalType={this.state.showModalType}
                readOnly={this.state.readOnly}
                sheetFilterId={this.props.config.sheetFilterId}
                pendingTransfer={this.props.pendingTransfer}
            />
        );
    }

    public async componentDidUpdate(prevProps: Props): Promise<void> {
        if (this.props.viewId !== prevProps.viewId) {
            await this.setSafeSharingEnabledState();
            this.setState(this.transformPropsData());
        } else if (this.props.cancelChanges && this.props.cancelChanges !== prevProps.cancelChanges) {
            this.setState(this.transformPropsData());
        } else if (this.props.shareErrors && this.props.shareErrors !== prevProps.shareErrors) {
            this.setState(this.transformPropsData());
        }

        if (this.props.pendingTransfer && prevProps.pendingTransfer !== this.props.pendingTransfer) {
            this.setState({ newOwner: undefined });
        }

        if (this.props.viewShares !== prevProps.viewShares) {
            const currentUserIsAdminOrOwner = this.props.viewShares.some(
                (viewShare) =>
                    viewShare.email === this.props.currentUser &&
                    (viewShare.accessLevel === AccessLevel.ADMIN || viewShare.accessLevel === AccessLevel.OWNER)
            );

            // If current user is not an owner or admin, then redirect user to home page. This is needed when user removes them self as admin.
            if (!currentUserIsAdminOrOwner) {
                this.props.setAppStageRedirect('/');
            }
        }
    }

    public async componentDidMount(): Promise<void> {
        await this.setSafeSharingEnabledState();
        this.setState(this.transformPropsData());
    }

    public handleOnUsersChange = (shareUsers: Contact[] = []): void => {
        this.setState({ shareUsers }, this.onContainerChange);
    };

    public handleOnAdminsChange(admins: Contact[] | undefined): void {
        // If current user is not owner AND not found in the new list of admins AND not found in the new list of shared users,
        // show modal to confirm whether the user really wants to remove him/herself as admin & lose access to view.
        // Store these admins as 'pendingAdmins' so that the update can be completed later if user chooses 'Remove me' from modal
        const users = this.state.shareUsers;
        if (
            !this.isCurrentUserViewOwner() &&
            !(admins && admins.some((admin) => this.props.currentUser === admin.email)) &&
            !(users && users.some((user) => this.props.currentUser === user.email))
        ) {
            this.pendingAdmins = admins;
            this.setState({ showModalType: AdminPanelPermissionsModalType.WARN_ADMIN });
            // Otherwise current user is still either in new admin list or owner, so update state w/o showing modal
        } else {
            this.setState({ admins }, this.onContainerChange);
        }
    }

    public handleOnGroupsChange(groups: MultiSelectItem[] = []): void {
        this.setState({ groups }, this.onContainerChange);
    }

    public handleOnDomainsChange(domains: MultiSelectItem[] = []): void {
        const lastIndex = domains.length - 1;

        if (domains.length > 0) {
            // Check validity on newest email in list (others should already have the isValid key)
            domains[lastIndex].isValid = this.isDomainValid(domains[lastIndex].value);
        }
        this.setState({ domains }, this.onContainerChange);
    }

    public handleWarnAdminCancel = (): void => {
        // Close modal (& don't update admins)
        this.pendingAdmins = undefined;
        this.setState({
            showModalType: AdminPanelPermissionsModalType.NONE,
        });
    };

    public handleWarnAdminConfirm = () => {
        // Update admins with 'pendingAdmins' (set before modal warns user about change)
        this.setState(
            {
                showModalType: AdminPanelPermissionsModalType.NONE,
                admins: this.pendingAdmins,
            },
            this.onContainerChange
        );
    };

    // Called when user select a contact in  the contact picker for ownership transfer
    private handleNewOwnerChange(value: Contact | undefined): void {
        this.setState({ newOwner: value }, this.onContainerChange);
    }

    private handleUserValidateNewOption(newOption: string, allowFreeTextOptionCallback: (isValid: boolean) => void): void {
        allowFreeTextOptionCallback(isEmailValid(newOption));
    }

    private isContainerValid(): boolean {
        return (
            this.state.admins.every((admin) => admin.isValid === true) &&
            this.state.shareUsers.every((user) => user.isValid === true) &&
            this.state.domains.every((domain) => this.isDomainValid(domain.value))
        );
    }

    private isDomainValid(domain: string): boolean {
        if (!this.state || this.state.safeSharingEnabled) {
            return validator.isFQDN(domain);
        } else {
            return validator.isFQDN(domain) && !isDomainBlocklisted(domain);
        }
    }

    private isCurrentUserViewOwner(): boolean {
        return this.props.currentUser === this.state.owner.value;
    }

    private onContainerChange(): void {
        const admins = this.transformContactsToViewShares(this.state.admins, AccessLevel.ADMIN, AccessType.INDIVIDUAL);
        const shareUsers = this.transformContactsToViewShares(this.state.shareUsers, AccessLevel.USER, AccessType.INDIVIDUAL);
        const groups = this.transformMultiSelectItemsToViewShares(this.state.groups, AccessLevel.USER, AccessType.GROUP);
        const domains = this.transformMultiSelectItemsToViewShares(this.state.domains, AccessLevel.USER, AccessType.DOMAIN);

        const result: List<ViewShareBasic> = fromJS([...admins, ...shareUsers, ...groups, ...domains]);

        const resultNewOwner = this.state.newOwner ? { ...this.state.newOwner } : undefined;

        const value: AdminPanelOnChange = {
            isValid: this.isContainerValid(),
            data: {
                shares: result,
                newOwner: resultNewOwner,
            },
        };

        this.props.onChange(value);
    }

    private sortAndTransformGroupsToMultiSelectItems(smartsheetGroups: Group[], controlIdPrefix: string): MultiSelectItem[] {
        return smartsheetGroups
            ? smartsheetGroups.sort(dynamicSort('name')).map((smartsheetGroup: Group) => ({
                  value: smartsheetGroup.id,
                  label: smartsheetGroup.name,
                  isValid: true,
                  controlIdPrefix,
              }))
            : [];
    }

    private transformToViewShare(value: string | undefined, accessLevel: AccessLevel, accessType: AccessType): ViewShareBasic {
        let email: string | undefined | null = null;
        let grantedToGroupId: string | undefined | null = null;
        let grantedToDomain: string | undefined | null = null;

        switch (accessType) {
            case AccessType.INDIVIDUAL:
                email = value;
                break;
            case AccessType.GROUP:
                grantedToGroupId = value;
                break;
            case AccessType.DOMAIN:
                grantedToDomain = value;
                break;
        }

        return {
            email,
            grantedToGroupId,
            grantedToDomain,
            accessLevel,
        };
    }

    private transformContactsToViewShares(contacts: Contact[], accessLevel: AccessLevel, accessType: AccessType): ViewShareBasic[] {
        if (!contacts || !contacts.length) {
            return [];
        }

        return contacts.map((contact) => this.transformToViewShare(contact.email, accessLevel, accessType));
    }

    private transformMultiSelectItemsToViewShares(
        multiSelectItems: MultiSelectItem[],
        accessLevel: AccessLevel,
        accessType: AccessType
    ): ViewShareBasic[] {
        if (!multiSelectItems || !multiSelectItems.length) {
            return [];
        }

        return multiSelectItems.map((multiSelectItem) => this.transformToViewShare(multiSelectItem.value, accessLevel, accessType));
    }

    private transformPropsData(): Omit<State, 'safeSharingEnabled'> {
        let owner: MultiSelectItem | undefined;
        const admins: Contact[] = [];
        const shareUsers: Contact[] = [];
        const groups: MultiSelectItem[] = [];
        const domains: MultiSelectItem[] = [];
        const readOnly = !this.props.userInOwnerOrg;
        const smartsheetUsersByEmails = this.createSmartsheetUserMap(this.props.selectedUsers.data);
        const smartsheetAdmins = this.createSmartsheetUserMap(this.props.allAdmins);

        this.props.viewShares.forEach((viewShare: ViewShareBasic) => {
            switch (viewShare.accessLevel) {
                case AccessLevel.USER:
                    if (viewShare.grantedToDomain) {
                        domains.push({
                            value: viewShare.grantedToDomain,
                            label: viewShare.grantedToDomain,
                            isValid: this.isDomainValid(viewShare.grantedToDomain),
                            controlIdPrefix: ControlId.DOMAINS,
                        });
                    } else if (viewShare.grantedToGroupId) {
                        groups.push({
                            value: viewShare.grantedToGroupId,
                            label: viewShare.grantedToGroupName || viewShare.grantedToGroupId,
                            isValid: true,
                            controlIdPrefix: ControlId.GROUPS,
                        });
                    } else {
                        shareUsers.push(this.findSmartsheetUserAndCreateContact(viewShare, smartsheetUsersByEmails));
                    }
                    break;
                case AccessLevel.ADMIN:
                    admins.push(this.findSmartsheetUserAndCreateContact(viewShare, smartsheetAdmins));
                    break;
                case AccessLevel.OWNER:
                    owner = this.mergeSmartsheetUser(viewShare, smartsheetAdmins, ControlId.OWNER);
                    break;
            }
        });

        if (!owner) {
            throw new Error(this.props.languageElements.ADMIN_PANEL_PERMISSIONS_OWNER_DOES_NOT_EXIST);
        }

        const availableAdmins: SmartsheetUser[] = [];
        this.props.allAdmins.forEach((admin: SmartsheetUser) => {
            if (admin.email.toLowerCase() !== owner!.value.toLowerCase()) {
                availableAdmins.push(admin);
            }
        });

        return {
            owner: owner!,
            newOwner: undefined,
            availableAdmins,
            admins,
            shareUsers,
            groups,
            domains,
            readOnly,
            showModalType: AdminPanelPermissionsModalType.NONE,
        };
    }

    private findSmartsheetUserAndCreateContact(viewShare: ViewShareBasic, smartsheetUsers: Map<string, SmartsheetUser>): Contact {
        const smartsheetUser: SmartsheetUser | undefined = smartsheetUsers.get(viewShare.email!);

        if (smartsheetUser) {
            return { ...mapUserToContact(smartsheetUser), isValid: true };
        }

        return {
            email: viewShare.email!,
            name: viewShare.email!,
            isValid: isEmailValid(viewShare.email!),
        };
    }

    private mergeSmartsheetUser(viewShare: ViewShareBasic, smartsheetUsers: Map<string, SmartsheetUser>, controlIdPrefix: string): MultiSelectItem {
        const smartsheetUser: SmartsheetUser | undefined = smartsheetUsers.get(viewShare.email!);

        if (smartsheetUser) {
            return {
                value: viewShare.email!,
                label: smartsheetUser!.name ? smartsheetUser!.name! : smartsheetUser!.email,
                isValid: true,
                controlIdPrefix,
            };
        }

        return {
            value: viewShare.email!,
            label: viewShare.email!,
            isValid: isEmailValid(viewShare.email!),
            controlIdPrefix,
        };
    }

    private createSmartsheetUserMap(data: SmartsheetUser[]): Map<string, SmartsheetUser> {
        return new Map<string, SmartsheetUser>(data.map((item: SmartsheetUser): [string, SmartsheetUser] => [item.email, item]));
    }

    private getInitialState(): State {
        const transformedData = this.transformPropsData();

        return { ...transformedData, safeSharingEnabled: false };
    }

    private async setSafeSharingEnabledState(): Promise<void> {
        if (!this.props.safeSharingFeature) {
            return;
        }

        // call config setting client
        const safeSharingEnabled = await configSettingClient.getSafeSharingEnabledForUserOrg();
        this.setState({ safeSharingEnabled });
    }
}

const mapState = createStructuredSelector({
    allGroups: createSelector(makeSelectSmartsheetGroups(), (stateSmartsheetGroups) => stateSmartsheetGroups!),
    allUsers: createSelector(makeSelectSmartsheetUsers(), (stateSmartsheetUsers) => stateSmartsheetUsers!),
    selectedUsers: createSelector(makeSelectSmartsheetUsersByEmails(), (stateSmartsheetUsersByEmails) => stateSmartsheetUsersByEmails!),
    allAdmins: createSelector(makeSelectSmartsheetAdmins(), (stateSmartsheetAdmins) => stateSmartsheetAdmins!),
    shareErrors: makeSelectShareErrors(),
    safeSharingFeature: safeSharingFeatureSelector,
});

const mapDispatch = (dispatch: Dispatch): DispatchProps => {
    return {
        storeShareErrors: () => dispatch(Actions.storeShareErrors()),
        setAppStageRedirect: (redirectUrl: string) => dispatch(AppActions.Actions.setAppStageRedirect(redirectUrl)),
    };
};

export default withLanguageElementsHOC(connect(mapState, mapDispatch)(AdminPanelPermissionsContainer));
