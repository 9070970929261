import * as React from 'react';
import StatusViewIcon from '../../components/StatusViewIcon';
import StatusViewLogo from '../../components/StatusViewLogo';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { AUTHENTICATE_URL } from './Constants';
import './Login.css';

const ControlId = {
    LOGIN: 'lic-1',
    EMAIL: 'lic-2',
};

/**
 * The Login component renders in the scenario where the user logs out of the app.
 */
const Login: React.FC = () => {
    const languageElements = useLanguageElements();
    return (
        <div className="loginBackground">
            <div className="loginForm">
                <StatusViewIcon />
                <StatusViewLogo />
                <a href={AUTHENTICATE_URL} target={'_self'}>
                    <button data-client-id={ControlId.LOGIN} tabIndex={-1}>
                        {languageElements.LOGIN_TEXT}
                    </button>
                </a>
                <p />
            </div>
        </div>
    );
};

export default Login;
