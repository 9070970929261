import * as React from 'react';
import FavoritesPanel from './FavoritesPanel/FavoritesPanel';
import HomeFilterPanel from './HomeFilterPanel';
import './LeftPanel.css';
import LeftPanelType from './LeftPanelType';
import RecentsPanel from './RecentsPanel/RecentsPanel';
import SkinnyRail from './SkinnyRail/SkinnyRail';

interface OwnProps {
    activeSidePanel: LeftPanelType;
    activeViewId: string | undefined;
    leftSidePanelOpened: boolean;
    userIsLicensed: boolean;
    userIsLoaded: boolean;
}

export const LeftPanel: React.SFC<OwnProps> = props => {

    return (
        <React.Fragment>
            <SkinnyRail
                leftSidePanelOpened={props.leftSidePanelOpened}
                activeSidePanel={props.activeSidePanel}
                userIsLicensed={props.userIsLicensed}
            />
            {props.activeSidePanel === LeftPanelType.HOME && <HomeFilterPanel leftSidePanelOpened={props.leftSidePanelOpened} />}
            {props.activeSidePanel === LeftPanelType.RECENTS && <RecentsPanel
                activeViewId={props.activeViewId}
                userIsLoaded={props.userIsLoaded}
            />}
            {props.activeSidePanel === LeftPanelType.FAVORITES && <FavoritesPanel
                activeViewId={props.activeViewId}
                userIsLoaded={props.userIsLoaded}
            />}
        </React.Fragment>

    );
};

export default LeftPanel;
