import { AsyncStatus } from '../../../common/enums';
import { HomeView } from '../../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import Spinner, { Color, Size } from '../../../components/Spinner';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { homeViewStatusSelector, pinnedViews, recentViews } from '../../Home';
import EmptyStateLeftRail from '../EmptyStateLeftRail/EmptyStateLeftRail';
import PanelFrame from '../PanelFrame/PanelFrame';
import RecentsList from './RecentsList';
import './RecentsPanel.css';

export interface OwnProps {
    activeViewId?: string;
    userIsLoaded: boolean;
}

interface StateProps {
    recentStatus: AsyncStatus;
    recentViews: HomeView[];
    pinnedViews: HomeView[];
}

export type RecentsPanelProps = OwnProps & StateProps;

export const RecentsPanel: React.FC<RecentsPanelProps> = (props) => {
    const languageElements = useLanguageElements();

    if (!props.userIsLoaded) {
        return null;
    }

    /**
     * Renders Recents panel content depending on AsyncStatus for recents. Note that it is not checking the pinnedStatus which currently
     * always matches recents status.
     */
    const getPanelContent = (): React.ReactNode => {
        switch (props.recentStatus) {
            case AsyncStatus.ERROR:
                return <EmptyStateLeftRail message={languageElements.RECENTS_ERROR_MESSAGE} classNames="left-panel-error" />;

            case AsyncStatus.DONE:
                return getRecentViews();

            case AsyncStatus.IN_PROGRESS:
                if (props.recentViews && props.recentViews.length > 0) {
                    return getRecentViews();
                }
                return <Spinner color={Color.BLUE} size={Size.SMALL} />;
        }

        return null;
    };

    const getRecentViews = (): React.ReactNode => {
        const unpinnedViews = props.recentViews.filter((recentView) => !recentView.pinned);
        return <RecentsList activeViewId={props.activeViewId} unpinnedViews={unpinnedViews} pinnedViews={props.pinnedViews} />;
    };

    const panelContent = getPanelContent();

    return (
        <PanelFrame titleLabel={languageElements.RECENTLY_OPENED} dataClientId={AutomationIds.RECENT_PANEL_TITLE}>
            {panelContent}
        </PanelFrame>
    );
};

const mapState = createStructuredSelector({
    recentStatus: homeViewStatusSelector,
    recentViews,
    pinnedViews,
});

export default connect<StateProps>(mapState)(RecentsPanel);
