export enum Feature {
    FAKE = 'FAKE', // DO NOT REMOVE - Example feature flag enum
    GS_GET_COLUMNS = 'GS_GET_COLUMNS',
    POLLING_DELAY = 'POLLING_DELAY',
    WORKSPACE_ALL = 'WORKSPACE_ALL',
    PAGINATION_1 = 'PAGINATION_1',

    // IMPORTANT: Required for GOV implementation due to unsupported services in GOV
    SAFE_SHARING = 'SAFE_SHARING',
    WORKSPACE_SEARCH = 'WORKSPACE_SEARCH',
    DISABLED_CONTACT_PICKER_ON_DEMAND = 'DISABLED_CONTACT_PICKER_ON_DEMAND',
    ATTACHMENTS_AND_CONVERSATIONS = 'ATTACHMENTS_AND_CONVERSATIONS',
}
