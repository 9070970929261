import { SmartsheetUser } from '../../../../common/interfaces';
import classNames from 'classnames';
import * as React from 'react';
import { default as AtMentionsWithHOC, AtMentions as AtMentionsClass } from '../AtMentions/AtMentions';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../../../language-elements/withLanguageElementsHOC';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';

export interface CommentInputProps {
    className: string;
    currentUser?: SmartsheetUser;
    currentUserIcon?: JSX.Element;

    viewId: string;
    rowId: number;
    reportSheetId?: number;
    value?: string;

    isEditing?: boolean;
    onSave: (text: string) => void;
    onChange: (text: string) => void;
    onCancel?: () => void;
}

interface State {
    currentComment: string;
    showHelpText: boolean;
}

const ControlId = {
    SAVE: 'cmt-1',
    CANCEL: 'cmt-2',
};

export class CommentInput extends React.Component<CommentInputProps & LanguageElementsProp, State> {
    public atMentionsRef = React.createRef<AtMentionsClass>();

    public constructor(props: CommentInputProps & LanguageElementsProp) {
        super(props);
        this.state = {
            currentComment: this.props.value || '',
            showHelpText: false,
        };
    }

    public render(): JSX.Element {
        const commentHelpTextStyle: React.CSSProperties = this.state.showHelpText ? {} : { visibility: 'hidden' };
        return (
            <div data-client-type={AutomationTypes.COMMENT_REPLY_ITEM_TYPE} className={this.props.className}>
                {this.props.currentUser && this.props.currentUserIcon}
                <div className={'comment-textarea-wrap'}>
                    <AtMentionsWithHOC
                        ref={this.atMentionsRef}
                        viewId={this.props.viewId}
                        rowId={this.props.rowId}
                        reportSheetId={this.props.reportSheetId}
                        text={this.props.value}
                        onChange={this.updateComment}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onSubmit={this.handleSubmit}
                    />

                    {/* `div` wrapping the help text and send icon. Visually, this appears inside the text area.*/}
                    <div className={'button-list'}>
                        <div>
                            <div className={'comment-help-text'} style={commentHelpTextStyle}>
                                {this.props.languageElements.COMMENT_HELP_TEXT}
                            </div>
                        </div>

                        {/* Do not show the send icon when the Save and Cancel buttons are displayed. */}
                        {!this.props.isEditing && (
                            <button
                                data-client-id={AutomationIds.COMMENT_COMMENTS_SEND}
                                className={classNames('item-icon', 'icon-send', {
                                    active: this.state.currentComment.trim() !== '',
                                    hidden: this.props.isEditing,
                                })}
                                onClick={this.handleClick}
                                value={this.props.languageElements.COMMENT_SEND}
                                aria-label={this.props.languageElements.COMMENT_SEND}
                            />
                        )}
                    </div>
                </div>
                {this.props.isEditing && (
                    <div className={'save-and-cancel-buttons'}>
                        <button onClick={this.handleClick} className="btn state-button btn-primary" data-client-id={ControlId.SAVE}>
                            {this.props.languageElements.BUTTON_TEXT_SAVE}
                        </button>
                        <button onClick={this.props.onCancel} className="btn details-data-button btn-secondary" data-client-id={ControlId.CANCEL}>
                            {this.props.languageElements.BUTTON_TEXT_CANCEL}
                        </button>
                        <div style={{ clear: 'both' }} />
                    </div>
                )}
            </div>
        );
    }

    public componentDidMount(): void {
        this.setFocusAfterCommentSubmission();
    }

    private setFocusAfterCommentSubmission = () => {
        const commentsPanel = document.querySelector('.comments-panel');
        if (commentsPanel) {
            // Copied from app-core:
            // - dev2/web/javascript/legacyApp/src/app2/ReactIntegrationManager.ts:117
            // - dev2/web/javascript/legacyApp/src/core/Common.ts: 757
            const mentionsFlyoutNode = document.createElement('div');
            mentionsFlyoutNode.className = 'mentionsFloatingPortal';
            commentsPanel.appendChild(mentionsFlyoutNode);
        }

        if (this.atMentionsRef.current) {
            this.atMentionsRef.current.focus();
        }
    };

    private handleSubmit = (text: string) => {
        // If the user pressed "enter" in the AtMentions control without making any edits, then just cancel the edit.
        if (this.props.value === text) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        } else {
            this.props.onSave(text);
        }
    };

    private handleClick = () => {
        this.handleSubmit(this.state.currentComment);
        this.setState({ currentComment: '' });
    };

    private updateComment = (comment: string) => {
        this.setState({ currentComment: comment }, () => this.props.onChange(comment));
    };

    private handleFocus = () => this.setState({ showHelpText: true });
    private handleBlur = () => this.setState({ showHelpText: false });
}

export default withLanguageElementsHOC(CommentInput);
