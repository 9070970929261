import { User } from './interfaces/User';
import { ActionsUnion } from '../../store';
import { createAction } from '../../store/Utils/CreateAction';

export enum ActionTypes {
    LOG_IN = 'LOG_IN',
    LOG_OUT = 'LOG_OUT',
}

export const Actions = {
    logIn: (user: User) => createAction(ActionTypes.LOG_IN, user),
    logOut: () => createAction(ActionTypes.LOG_OUT),
};

export type Actions = ActionsUnion<typeof Actions>;
