import styled from '@emotion/styled';
import { Avatar } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { useState } from 'react';
import { dateTimeFormat } from '../../../../common/DateTimeFormat';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';
import CommentMenu from '../../../../components/Buttons/CommentMenu/CommentMenu';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import CommentInput from './CommentInput';
import { UnsavedComment } from './Conversation';
import { getInitials } from './ConversationUtils';
import Reply from './Reply';
import { CommentType, User } from './types';

interface CommentProps {
    viewId: string;
    rowId: string;
    comment: CommentType;
    unsavedValue: UnsavedComment[];
    reportSheetId?: number;
    currentUser: User;
    newCommentsAllowed: boolean;
    onSave: (unsavedItem: UnsavedComment) => Promise<void>;
    onClickDeleteCommentThread: (commentId: string) => Promise<void>;
    onClickDeleteComment: (commentId: string) => Promise<void>;
    onChangeUnsavedReplyOrComment: (unsavedItem: UnsavedComment) => void;
}

const Comment: React.FC<CommentProps> = ({
    viewId,
    rowId,
    comment,
    unsavedValue,
    reportSheetId,
    currentUser,
    newCommentsAllowed,
    onChangeUnsavedReplyOrComment,
    onSave,
    onClickDeleteComment,
    onClickDeleteCommentThread,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isReplying, setIsReplying] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const createdAt = dateTimeFormat(currentUser.locale, currentUser.timezone).format(new Date(comment.createdAt));
    const mostRecentReplies = comment.replies.slice(comment.replies.length - 2);
    const leastRecentReplies = comment.replies.slice(0, comment.replies.length - 2);
    const currentUserIsCommentOwner = comment.createdByUser?.emailAddress?.toLowerCase() === currentUser.emailAddress.toLowerCase();

    const languageElements = useLanguageElements();

    const handleDeleteReply = (replyId: string) => {
        onClickDeleteComment(replyId);
    };

    const handleSave = async (unsavedItem: UnsavedComment) => {
        await onSave(unsavedItem);
        setIsReplying(false);
        setIsEditing(false);
    };

    const getUnsavedItemByCommentId = (commentId: string) => {
        return unsavedValue.find((item) => item.commentId === commentId);
    };

    const renderReplies = (commentReplies: CommentType[]) =>
        commentReplies?.map((reply) => (
            <Reply
                key={reply.id}
                viewId={viewId}
                rowId={rowId}
                reportSheetId={reportSheetId}
                unsavedValue={{
                    commentId: reply.id,
                    parentCommentId: reply.parentCommentId,
                    text: getUnsavedItemByCommentId(reply.id)?.text || reply.text,
                }}
                reply={reply}
                currentUser={currentUser}
                onClickDeleteReply={() => {
                    handleDeleteReply(reply.id);
                }}
                onChangeUnsavedReplyOrComment={(unsavedComment: UnsavedComment) => {
                    onChangeUnsavedReplyOrComment(unsavedComment);
                }}
                onSave={onSave}
            />
        ));

    return (
        <div>
            <div className="comment-wrap" data-client-type={AutomationIds.COMMENT_COMMENTS_ITEM}>
                <div className="comment-content">
                    <CommentHeader className="comment-header">
                        <AvatarStyled
                            size="small"
                            clientId={AutomationTypes.CONTACT_ICON}
                            initials={getInitials(comment.createdByUser.firstName, comment.createdByUser.lastName)}
                            email={comment.createdByUser.emailAddress}
                            src={comment.createdByUser.profileImage?.url ?? ''}
                            aria-label="Profile Image"
                        />
                        <FlexGrow>
                            <span className="comment-creator" data-client-id={AutomationIds.COMMENT_COMMENTS_CREATOR}>
                                <b>
                                    {comment.createdByUser.firstName ?? ''} {comment.createdByUser.lastName ?? ''}
                                </b>
                            </span>

                            {/* Hide the created-at date if the comment is being edited. */}
                            {!isEditing && (
                                <span className="comment-date" data-client-id={AutomationIds.COMMENT_COMMENTS_DATE}>
                                    | {createdAt}
                                </span>
                            )}
                        </FlexGrow>
                        <CommentMenu
                            for={comment.text}
                            onClickEditComment={currentUserIsCommentOwner ? () => setIsEditing(true) : undefined}
                            onClickDeleteComment={currentUserIsCommentOwner ? () => onClickDeleteComment(comment.id) : undefined}
                            onClickDeleteDiscussion={currentUserIsCommentOwner ? () => onClickDeleteCommentThread(comment.id) : undefined}
                        />
                    </CommentHeader>

                    {!isEditing ? (
                        <div className="comment-text" data-client-id={AutomationIds.COMMENT_COMMENTS_TEXT}>
                            {comment.text}
                        </div>
                    ) : (
                        <CommentInput
                            className={'add-comment-wrap'}
                            viewId={viewId}
                            rowId={rowId}
                            reportSheetId={reportSheetId}
                            value={getUnsavedItemByCommentId(comment.id)?.text || comment.text}
                            commentId={comment.id}
                            parentCommentId={comment.id}
                            isEditing={true}
                            currentUser={currentUser}
                            onSave={(unsavedItem: UnsavedComment) => handleSave(unsavedItem)}
                            onChangeUnsavedReplyOrComment={(unsavedComment: UnsavedComment) => {
                                onChangeUnsavedReplyOrComment(unsavedComment);
                            }}
                            closeCommentInput={() => setIsEditing(false)}
                            onCancel={() => setIsEditing(false)}
                        />
                    )}

                    {leastRecentReplies.length > 0 && isCollapsed && (
                        <div style={{ display: 'block' }}>
                            <div>
                                <a
                                    data-client-id={AutomationIds.COMMENT_COMMENTS_VIEW_PREVIOUS}
                                    className="view-previous-comments"
                                    onClick={() => {
                                        setIsCollapsed(false);
                                    }}
                                >
                                    <div className="item-icon icon-message" />
                                    {languageElements.TEMPLATE.COMMENT_ITEM_VIEW_PREVIOUS_REPLIES(leastRecentReplies.length)}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                {leastRecentReplies.length > 0 && !isCollapsed && <div style={{ display: 'block' }}>{renderReplies(leastRecentReplies)}</div>}
                <div>{renderReplies(mostRecentReplies)}</div>

                {newCommentsAllowed && (
                    <div>
                        {isReplying ? (
                            <CommentInput
                                className={'add-comment-wrap sub-comment'}
                                viewId={viewId}
                                rowId={rowId}
                                reportSheetId={reportSheetId}
                                commentId={'0'}
                                parentCommentId={comment.id}
                                value={getUnsavedItemByCommentId('0')?.text || ''}
                                currentUser={currentUser}
                                onSave={(unsavedItem: UnsavedComment) => handleSave(unsavedItem)}
                                onChangeUnsavedReplyOrComment={onChangeUnsavedReplyOrComment}
                                closeCommentInput={() => {
                                    setIsReplying(false);
                                }}
                                onCancel={() => setIsReplying(false)}
                                isEditing={false}
                            />
                        ) : (
                            <div className="reply-comment-link sub-comment">
                                <a
                                    data-client-id={AutomationIds.COMMENT_COMMENTS_REPLY}
                                    className="reply-comment-link-text"
                                    onClick={() => {
                                        setIsReplying(true);
                                    }}
                                >
                                    <div className="item-icon icon-reply" />
                                    {languageElements.COMMENT_ITEM_REPLY}
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="divider" />
        </div>
    );
};

export default Comment;

const AvatarStyled = styled(Avatar)`
    margin-left: -35px;
    margin-right: 7px;
`;

export const CommentHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexGrow = styled.div`
    flex-grow: 1;
`;
