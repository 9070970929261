/* eslint no-var:"off", eqeqeq:"off", no-bitwise:"off" -- code from app-core */
// noinspection ES6ConvertVarToLetConst

// Used in AtMentions.tsx. Copied from app-core: dev2/web/javascript/legacyApp/src/core/Common.ts

/**
 * Simple string hash function.
 */
export function hashCode(str: string): number {
    var hash = 0;
    if (str.length == 0) { return hash; }
    for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}
