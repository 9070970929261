import * as React from 'react';
import { BaseComponent } from '../../components/Base';
import { ActionTypes } from '../App/Actions';
import * as AppActions from '../App/Actions';
import { ActionWithPayload } from '../../store/Utils/CreateAction';
import LeftPanel from './LeftPanel';
import LeftPanelType from './LeftPanelType';

interface OwnProps {
    activeSidePanel: LeftPanelType;
    activeViewId: string | undefined;
    leftSidePanelOpened: boolean;
    userIsLicensed: boolean;
    userIsLoaded: boolean;
    setActiveSidePanel: (panel: LeftPanelType) => ActionWithPayload<ActionTypes.SET_ACTIVE_SIDE_PANEL, LeftPanelType>;
    toggleLeftSidePanel: () => AppActions.Actions;
}

export class LeftPanelContainer extends BaseComponent<OwnProps> {
    public constructor(props: OwnProps) {
        super(props);
    }

    public render(): React.ReactNode {
        return (
            <LeftPanel
                activeSidePanel={this.props.activeSidePanel}
                activeViewId={this.props.activeViewId}
                leftSidePanelOpened={this.props.leftSidePanelOpened}
                userIsLicensed={this.props.userIsLicensed}
                userIsLoaded={this.props.userIsLoaded}
            />
        );
    }

    public componentDidUpdate(prevProps: OwnProps): void {
        if (this.props.activeViewId && this.props.activeViewId !== prevProps.activeViewId) {
            // Close the left panel if it's open
            if (this.props.leftSidePanelOpened) {
                this.props.toggleLeftSidePanel();
            }
        }
    }
}

export default LeftPanelContainer;
