import { useEffect } from 'react';

interface ResizeHeightOptions {
    resizeHeightCallback: (height: number) => void;
    targetRef?: React.RefObject<HTMLElement>;
}

export const useResizeHeight = ({ resizeHeightCallback, targetRef }: ResizeHeightOptions) => {
    useEffect(() => {
        if (!targetRef?.current) {
            return;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                for (const entry of entries) {
                    const size = entry.borderBoxSize?.[0] || entry.contentRect;

                    if (size && entry.target === targetRef.current) {
                        const height = size.inlineSize || entry.target.clientHeight;
                        resizeHeightCallback(height);
                        break;
                    }
                }
            });
        });

        resizeObserver.observe(targetRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [targetRef, resizeHeightCallback]);
};
